export const environment = {
    production: false,
    api: {
        urlCore: 'https://stg-api-cms-admin.wepay4u.com/api/v1/',
        urlBase: 'https://stg-api-cms-admin.wepay4u.com/api/',
        urlCoreAdmin: 'https://stg-api-core-admin.wepay4u.com',
        urlMerchant: 'https://stg-api-core-merchant.wepay4u.com/v1/merchant/',
        urlCmsMerchant: 'https://stg-api-cms-merchant.wepay4u.com/api/v1/',
        urlBank: 'https://stg-api-core-bank.wepay4u.com/v1/',
        urlProvider: 'https://stg-api-core-provider.wepay4u.com/v1/',
        urlAcces: 'https://stg-api-core-access.wepay4u.com/v1/',
        urlFinance: 'https://stg-api-core-finance.wepay4u.com',
        urlReports: 'https://stg-api-core-reports.wepay4u.com'
    },
    maxMonthsSelect: 3,
    refreshMonitorTimer: 30000 //30 seconds default
};

